import { ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import React from "react";
import { matchPath, NavLink, useLocation } from "react-router-dom";

interface Props {
  to: string;
  icon: React.ReactNode;
  listItemText: string;
  altMatchPath?: string; // alternativer path der ebenfalls als path matching akzeptiert wird
}

const CustomListItem: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const location = useLocation();

  const isPathMatch = () => {
    if(!props.altMatchPath) return matchPath({ path: props.to, end: false }, location.pathname);
    else {
      var matches = matchPath({ path: props.to }, location.pathname);
      var altPathMatches = matchPath({ path: props.altMatchPath }, location.pathname);
      if (matches || altPathMatches) return true;
    }

    return false;
  }
  
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={NavLink}
        to={props.to}
        sx={{
          paddingLeft: "30px",
          "& span": { lineHeight: 2.5, fontWeight: isPathMatch() ? "bold" : undefined }
        }}
      >
        <ListItemIcon sx={{minWidth: "40px", color: isPathMatch() ? theme.palette.primary.main : undefined}}>
          {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.listItemText} />
      </ListItemButton>
    </ListItem>
  );
};

export default CustomListItem;
