import axios, { AxiosResponse } from "axios";
import { LoginDto } from "../interfaces/dto/LoginDto";
import { PasswordResetDto } from "../interfaces/dto/PasswordResetDto";

export const login = (loginDto: LoginDto) : Promise<AxiosResponse<string>> => {
    return axios.post("/authentication/login", loginDto);
};

export const recoverPassword = (email : string) : Promise<AxiosResponse<string>> => {
    return axios.post(`/authentication/recoverPassword?email=${email}`);
}

export const resetPassword = (passwordResetDto: PasswordResetDto) : Promise<AxiosResponse<string>> => {
    return axios.post(`/authentication/resetpassword`, passwordResetDto);
};

export const updatePassword = (oldPassword: string, newPassword : string) : Promise<AxiosResponse<string>>  => {
    return axios.put(`/authentication/updatePassword?oldPassword=${oldPassword}&newPassword=${newPassword}`);
}