import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Navigate, RouteProps } from "react-router-dom";
import SuspenseScreen from "../../containers/SuspenseScreen";
import { User } from "../../interfaces/User";
import { AppState } from "../../interfaces/general/app-state";

interface Props extends RouteProps {
  isLoading: boolean;
}

const ProtectedRoute: React.FC<Props> = (props: Props) => {
  const user = useSelector<AppState, User>((state) => state.user);
  const location = useLocation();
  const currentUrlEncoded =
    location.pathname != "/login/" ? encodeURIComponent(location.pathname) : "";

  const getRedirectUrl = () => {
    if (location.pathname != "/") return currentUrlEncoded;
    return "";
  };

  if(props.isLoading) {
    return <SuspenseScreen />
  }
  
  if (!user || user.id == "") {
    return <Navigate to={"/login/" + getRedirectUrl()} replace />;
  }

  return <>{props.children}</>;
};

export default ProtectedRoute;