import { Dispatch } from "redux";
import { ActionType, createAction } from "typesafe-actions";
import * as ClientCreditActions from "../client/credits";

const getCreditsRequest = createAction("user/GET_CREDITS_REQUEST")();
const getCreditsFailure = createAction("user/GET_CREDITS_FAILURE")();
const getCreditsSuccess = createAction("user/GET_CREDITS_SUCCESS")<string>();

export const getCredits = () => {
    return async (dispatch: Dispatch) => {
      dispatch(getCreditsRequest());
      return ClientCreditActions.getAvailable()
        .then((response: any) => {
          dispatch(getCreditsSuccess(response.data));         
        })
        .catch((error) => {
          dispatch(getCreditsFailure());
          return Promise.reject(error);
        });
    };
  };

  export type CreditActions = ActionType<
  | typeof getCreditsRequest
  | typeof getCreditsFailure
  | typeof getCreditsSuccess
>;

export const creditReducer = (state: number = 0, action: CreditActions) => {
  switch (action.type) {
    case "user/GET_CREDITS_SUCCESS":
      return action.payload;
    default:
      return state;
  }
};
