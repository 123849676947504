import { PaginatedList } from './../interfaces/general/PaginatedList';
import axios, { AxiosResponse } from "axios";
import { CreditPurchasePack } from "../interfaces/CreditPurchasePack";
import { CreditPurchaseEntry } from '../interfaces/CreditPurchaseEntry';

export const getAvailable = () : Promise<AxiosResponse<number>> => {
  return axios.get(`/credits/getAvailable`);
};

export const getPurchasePacks = () : Promise<AxiosResponse<CreditPurchasePack[]>> => {
  return axios.get(`/credits/getPurchasePacks`);
}

export const getPurchaseHistory = (page: number, rowsPerPage: number, searchQuery: string, onlyPending : boolean)  : Promise<AxiosResponse<PaginatedList<CreditPurchaseEntry>>> => {
  return axios.get(`/credits/getPurchaseHistory?page=${page}&rowsPerPage=${rowsPerPage}&searchQuery=${searchQuery}&onlyPending=${onlyPending}`);
}

export const buyCreditPack = (creditPackId : number) : Promise<AxiosResponse<string>> => {
  return axios.put(`/credits/buyCreditPack?creditPackId=${creditPackId}`);
}

export const addOrUpdatePurchasePack = (creditPurchasePack : CreditPurchasePack) : Promise<AxiosResponse<CreditPurchasePack>> => {
  return axios.post("/credits/addOrUpdatePurchasePack", creditPurchasePack);
} 

export const deleteCreditPurchasePack = (creditPurchasePackId : number) : Promise<AxiosResponse<string>> => {
  return axios.put(`/credits/deleteCreditPurchasePack?creditPurchasePackId=${creditPurchasePackId}`);
}

export const buyCreditPurchasePack = (creditPurchasePackId : number) : Promise<AxiosResponse<CreditPurchaseEntry>> => {
  return axios.put(`/credits/buyCreditPurchasePack?creditPurchasePackId=${creditPurchasePackId}`);
}

export const acceptCreditPurchaseEntry = (creditPurchaseEntryId : number) : Promise<AxiosResponse<string>> => {
  return axios.put(`/credits/acceptCreditPurchaseEntry?creditPurchaseEntryId=${creditPurchaseEntryId}`);
}

export const cancelCreditPurchaseEntry = (creditPurchaseEntryId : number) : Promise<AxiosResponse<string>> => {
  return axios.put(`/credits/cancelCreditPurchaseEntry?creditPurchaseEntryId=${creditPurchaseEntryId}`);
}