import axios, { AxiosResponse } from "axios";
import { User } from "../interfaces/User";
import { PaginatedList } from "../interfaces/general/PaginatedList";

export const getAll = (page: number, rowsPerPage: number, searchQuery: string): Promise<AxiosResponse<PaginatedList<User>>> => {
  return axios.get(`/users/getAll?page=${page}&rowsPerPage=${rowsPerPage}&searchQuery=${searchQuery}`);
};

export const getCurrent = (): Promise<AxiosResponse<User>> => {
  return axios.get("/users/getCurrent");
};

export const updateCurrent = (user: User): Promise<AxiosResponse<string>> => {
  return axios.post("/users/updateCurrent", user);
};

export const toggleUserActive = (userId: string): Promise<AxiosResponse<string>> => {
  return axios.put(`/users/toggleUserActive?userId=${userId}`);
};
