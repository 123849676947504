import * as React from "react";
import Routes from "../routes";
import Header, { drawerWidth } from "../components/header/Header";
import BrowserNotSupported from "../components/browser-not-supported/BrowserNotSupported";
import { useThunkDispatch } from "../utils/thunk-dispatch";
import { getCurrentUser } from "../features/user-feature";
import { isIE } from "react-device-detect";
import { useSelector } from "react-redux";
import { AppState } from "../interfaces/general/App-State";
import { Box } from "@mui/material";
import { User } from "../interfaces/User";

const App: React.FC = () => {
  const asyncDispatch = useThunkDispatch();
  const user = useSelector<AppState, User>((state) => state.user);

  React.useEffect(() => {
    asyncDispatch(getCurrentUser());    
  }, []);

  return (
    <>
      {user && user.id != "" && <Header />}
      <Box sx={user && user.id ? {width: { sm: `calc(100% - ${drawerWidth}px)` }, p: 3, pl: {sm: 5, xs: 3}, pr: {sm: 5, xs: 3}, ml: { sm: `${drawerWidth}px` }} : null}>
        {isIE ? <BrowserNotSupported /> : <Routes />}
      </Box>
    </>
  );
};

export default App;
