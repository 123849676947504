import { Salutation } from './enum/Salutation';
import { PartnerCompany } from "./PartnerCompany";

export interface User {
    [key: string]: any;
    id: string;
    email: string;
    userName: string;
    salutation: Salutation;
    firstName: string;
    lastName: string;
    roleName: string;
    languageId: number;
    languageShortName: string;
    isDeactivated: boolean;
    partnerCompany: PartnerCompany;
  }

  export const defaultUser : User = {
      id: "",
      email: "",
      userName: "",
      salutation: Salutation.Mr,
      firstName: "",
      lastName: "",
      roleName: "",
      languageId: 0,
      languageShortName: "",
      isDeactivated: false,
      partnerCompany: undefined,
  }