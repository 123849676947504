import { List, Toolbar } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import LogoutIcon from "@mui/icons-material/Logout";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomListItem from "./CustomListItem";
import GroupIcon from "@mui/icons-material/Group";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useSelector } from "react-redux";
import { User } from "../../interfaces/User";
import { AppState } from "../../interfaces/general/app-state";

const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector<AppState, User>((state) => state.user);

  return (
    <>
      <Toolbar sx={{ height: 85, display: { xs: "none", sm: "flex" }, "& img": { padding: 1 } }}>
        <img src="/public/img/logo.png" style={{ width: "100%" }} />
      </Toolbar>
      <Toolbar sx={{ display: { xs: "flex", sm: "none" } }}>
        <img src="/public/img/logo.png" style={{ width: "100%", padding: "10px 10px 10px 0" }} />
      </Toolbar>
      <List>
        {/* {user && user.roleName && user.roleName.toLowerCase() === "administrator" && (
          <CustomListItem to="/" altMatchPath="/detail/:id" icon={<GridViewIcon />} listItemText={t("navigation.dashboard")} />
        )} */}
        <CustomListItem altMatchPath="/" to="/offers" icon={<FileOpenIcon />} listItemText={t("navigation.offers")} />
        <CustomListItem to="/credits" icon={<MonetizationOnIcon />} listItemText={user && user.roleName && user.roleName.toLowerCase() === "administrator" ? t("navigation.adminTopUpCredits") : t("navigation.topUpCredits")} />
        {user && user.roleName && user.roleName.toLowerCase() === "administrator" && (
          <CustomListItem to="/user-management" icon={<GroupIcon />} listItemText={t("navigation.userManagement")} />
        )}
        {user && user.roleName && user.roleName.toLowerCase() === "administrator" && (
          <CustomListItem to="/partner-management" icon={<ApartmentIcon />} listItemText={t("navigation.partnerManagement")} />
        )}
        {user && user.roleName && user.roleName.toLowerCase() === "administrator" && (
          <CustomListItem to="/admin-management" icon={<AdminPanelSettingsIcon />} listItemText={t("navigation.adminManagement")} />
        )}
      </List>
      <List sx={{ display: "flex", flexGrow: 1, alignItems: "flex-end", flexDirection: "row", marginBottom: "25px" }}>
        <CustomListItem to="/logout" icon={<LogoutIcon />} listItemText={t("navigation.logout")} />
      </List>
    </>
  );
};

export default Navigation;
