import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Route, Routes as RouteWrapper } from "react-router-dom";
import * as loadable from "react-loadable";
import ProtectedRoute from "./components/_global/ProtectedRoute";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/general/app-state";
import { Request } from "./interfaces/general/request";

// const AsyncDashboardContainer = loadable({
//   loader: () => import(/* webpackChunkName: "Dashboard" */ "./containers/dashboard/Dashboard"),
//   loading: () => <CircularProgress />,
// });

const AsyncLoginContainer = loadable({
  loader: () =>
    import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"),
  loading: () => <CircularProgress />,
});
const AsyncLogoutContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Logout" */ "./containers/authentication/Logout"
    ),
  loading: () => <CircularProgress />,
});
const AsyncForgotPasswordContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ForgotPassword" */ "./containers/authentication/ForgotPassword"
    ),
  loading: () => <CircularProgress />,
});
const AsyncResetPasswordContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ResetPassword" */ "./containers/authentication/ResetPassword"
    ),
  loading: () => <CircularProgress />,
});
const AsyncProfileContainer = loadable({
  loader: () =>
    import(/* webpackChunkName: "Profile" */ "./containers/profile/Profile"),
  loading: () => <CircularProgress />,
});
const AsyncCreditsContainer = loadable({
  loader: () =>
    import(/* webpackChunkName: "Credits" */ "./containers/credits/Credits"),
  loading: () => <CircularProgress />,
});
const AsyncUserManagementContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UserManagement" */ "./containers/user-management/UserManagement"
    ),
  loading: () => <CircularProgress />,
});
const AsyncPartnerManagementContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PartnerManagement" */ "./containers/partner-management/PartnerManagement"
    ),
  loading: () => <CircularProgress />,
});
const AsyncAdminManagementContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AdminManagement" */ "./containers/admin-management/AdminManagement"
    ),
  loading: () => <CircularProgress />,
});
const AsyncOffersContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Offers" */ "./containers/offer-management/OfferManagement"
    ),
  loading: () => <CircularProgress />,
});
const AsyncOfferDetailContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "OfferDetail" */ "./containers/offer-management/OfferDetail"
    ),
  loading: () => <CircularProgress />,
});
const AsyncNotFoundContainer = loadable({
  loader: () =>
    import(
      /* webpackChunkName: "NotFound" */ "./containers/error-pages/NotFound"
    ),
  loading: () => <CircularProgress />,
});

const Routes: React.FC = () => {
  const requests = useSelector<AppState, Request[]>((state) => state.requests);
  const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");

  React.useEffect(() => {
    var userRequestString =
      requests.filter((x) => x.type === "user/GET_CURRENT").length > 0
        ? requests.filter((x) => x.type === "user/GET_CURRENT")[0].status
        : "REQUEST";
    setUserRequestStatus(userRequestString);
  }, [requests]);

  return (
    <RouteWrapper>
      <Route
        path="/"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncOffersContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncProfileContainer />
          </ProtectedRoute>
        }
      />
      <Route path="login" element={<AsyncLoginContainer />}>
        <Route path=":url" element={<AsyncLoginContainer />}>
          <Route path=":key" element={<AsyncLoginContainer />} />
        </Route>
      </Route>
      <Route
        path="/credits"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncCreditsContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-management"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncUserManagementContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-management"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncAdminManagementContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/partner-management"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncPartnerManagementContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/offers"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncOffersContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/offers/:id"
        element={
          <ProtectedRoute isLoading={userRequestStatus === "REQUEST"}>
            <AsyncOfferDetailContainer />
          </ProtectedRoute>
        }
      />
      <Route path="/logout" element={<AsyncLogoutContainer />} />
      <Route
        path="/forgot-password"
        element={<AsyncForgotPasswordContainer />}
      />
      <Route path="/reset-password" element={<AsyncResetPasswordContainer />} />

      <Route path="*" element={<AsyncNotFoundContainer />} />
    </RouteWrapper>
  );
};

export default Routes;
