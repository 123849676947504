import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./containers/App";
import SuspenseScreen from "./containers/SuspenseScreen";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { rootReducer } from "./features/root-reducer";
import { initialState } from "./interfaces/general/App-State";
import { configAxios } from "./utils/axios";
import "./i18n";
import { Suspense } from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const w: any = window as any;
const devtools: any = w.__REDUX_DEVTOOLS_EXTENSION__ ? w.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f;

const store = createStore(rootReducer, initialState, compose(applyMiddleware(thunk), devtools));

configAxios();

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
    h2: {
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
    h3: {
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
    h4: {
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
    h5: {
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
    h6: {
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
  },
  palette: {
    background: {
      default: "#F6F8FC",
    },
    primary: {
      main: "#53599A",
    },
    secondary: {
      main: "#1E1B18",
    },
    error: {
      main: "#D8315B",
    },
    success: {
      main: "#5AB49C",
      contrastText: "#ffffff",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Suspense fallback={<SuspenseScreen />}>
          <BrowserRouter>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
              <App />
            </LocalizationProvider>
          </BrowserRouter>
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
);
