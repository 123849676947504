import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import React from "react";
import Navigation from "../navigation/Navigation";
import { AppState } from "../../interfaces/general/app-state";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { User } from "../../interfaces/User";

export const drawerWidth = 275;
const Header: React.FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const user = useSelector<AppState, User>((state) => state.user);
  const availableCredits = useSelector<AppState, number>(
    (state) => state.credits
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const listenScrollEvent = () => {
    if (window.scrollY > 10) {
      setColor("white");
    } else {
      setColor("transparent");
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="profileMenu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem
        component={Link}
        to="/profile"
        onClick={() => setAnchorEl(null)}
      >
        {t("navigation.myProfile")}
      </MenuItem>
      <MenuItem
        component={Link}
        to="/credits"
        onClick={() => setAnchorEl(null)}
      >
        {user && user.roleName && user.roleName.toLowerCase() === "administrator" ? t("navigation.adminTopUpCredits") :t("navigation.topUpCredits")}
      </MenuItem>
      <MenuItem component={Link} to="/logout" onClick={() => setAnchorEl(null)}>
        {t("navigation.logout")}
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: { sm: color },
          color: { sm: "#000000" },
          boxShadow: { sm: color !== "white" ? "none" : undefined },
        }}
      >
        <Toolbar sx={{ pl: { sm: 5 }, pr: { sm: 5 }, pt: { sm: 1 } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="h6" sx={{ flexGrow: 1 }}>
            {user && user.roleName && user.roleName.toLowerCase() === "administrator" ? t("adminTitle") : t("title")}
          </Typography>
          <MenuItem onClick={() => navigate("/credits")}>
            <IconButton color="inherit">
              <MonetizationOnIcon />
            </IconButton>
            <p>{availableCredits} {t("navigation.credits")}</p>
          </MenuItem>
          <MenuItem onClick={handleMenuOpen}>
            <IconButton color="inherit">
              <AccountCircleIcon />
            </IconButton>
            <p>
              {user.firstName} {user.lastName}
            </p>
          </MenuItem>         
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Navigation />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderWidth: 0,
            },
          }}
          open
        >
          <Navigation />
        </Drawer>
      </Box>
      <Toolbar />
    </Box>
  );
};

export default Header;
